import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-admin-side-nav-bar',
  templateUrl: './admin-side-nav-bar.component.html',
  styleUrls: ['./admin-side-nav-bar.component.css']
})
export class AdminSideNavBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
